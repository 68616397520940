export const config = {
    FILE_PATH: "",
    API_ENDPOINT: "https://kratos-demo.icapkenya.org/api/v1/",
    SUMMARIZED_IFRAME_SOURCES: [],
    SUPERSET: {
      DOMAIN: 'https://shielddemo-analytics.icapkenya.org',
      API_ENDPOINT: "https://shielddemo-analytics.icapkenya.org/api/v1/security",
      API_PROVIDER: "db",
      USER_ADMINISTRATOR: {
        USERNAME: "demo_admin",
        PASSWORD: "AwesomeDemo2024#"
      },
      USER_GUEST: {
          USERNAME: "guest",
      },
      DASHBOARDS: [
        "92d09ef3-394a-4346-a731-1888f2087e82", // IBS
        "1c2254b6-15ff-415c-8609-d974aeadb29a", // IFBS/AFI
        "dcd36a25-f466-4ba0-b909-92a9ee9b9d4d", // SARI/ILI
        "5736daee-3d47-4c92-856d-3d510f7a89c0", // COVID-19
        "b876faf5-924b-473d-b6f8-fea2d1a14794", // EBS
        "c7c7e64d-c0c9-4364-b453-7433b400525e", // OUTBREAK
        "fea2280c-db7a-461f-bcf3-bb19a0ed95ba" // EBridge
      ],
    }
};

